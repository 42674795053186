
@import url('https://fonts.googleapis.com/css?family=Poppins:600,700');

@import './variables';

@import "./bootstrap/functions";
@import "./bootstrap/variables";
@import "./bootstrap/mixins";
@import "./bootstrap/root";
@import "./bootstrap/reboot";
@import "./bootstrap/type";
@import "./bootstrap/images";
@import "./bootstrap/badge";
@import "./bootstrap/grid";
@import "./bootstrap/forms";
@import "./bootstrap/buttons";
@import "./bootstrap/transitions";
@import "./bootstrap/dropdown";
@import "./bootstrap/button-group";
@import "./bootstrap/input-group";
@import "./bootstrap/custom-forms";
@import "./bootstrap/nav";
@import "./bootstrap/navbar";
@import "./bootstrap/card";
@import "./bootstrap/jumbotron";
@import "./bootstrap/alert";
@import "./bootstrap/list-group";
@import "./bootstrap/close";
@import "./bootstrap/modal";
@import "./bootstrap/carousel";
@import "./bootstrap/spinners";
@import "./bootstrap/utilities";
@import "./bootstrap/print";

@import './fontawesome/fontawesome.scss';
@import './fontawesome/light.scss';
@import './fontawesome/solid.scss';

body, html {
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  padding-top: 73px;
  @include media-breakpoint-down(md) {
    padding-top: 66px;
  }
}

.card {
  @extend .shadow;
}

.card-overlay-link {
}

.range-input {
  output {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 700;
    transform: translate(-1rem, -5.5rem);
    &::before {
      content :'';
      position: absolute;
      left: 0;
      top: 0;
      width: 2rem;
      height: 2rem;
      border-radius: 50% 50% 0;
      transform: rotate(45deg);
      background: $primary;
      z-index: -1;
    }
  }
}

.nav {

  &-item {
    @include media-breakpoint-down(md) {
      > .nav-link {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      &.dropdown {
        margin-bottom: 1rem;
        &.nav-item {
          > .nav-link {
            padding-bottom: .25rem;
          }
        }
        .dropdown-menu {
          padding: 0;
          display: block;
          box-shadow: none;
          border: none;
          .dropdown-item {
            padding: .125rem 0;
            color: $gray-500;
            &::before {
              content: '-';
              margin-right: .25rem;
            }
          }
        }
      }
    }
    @include media-breakpoint-up(lg) {
      .nav-link {
        text-align: center;
      }
      &.dropdown {
        .dropdown-menu {
          padding: 0;
          margin-top: 0;
          box-shadow: 0 0 9px rgba($black, .25);
          display: block;
          opacity: 0;
          pointer-events: none;
          transform: translateY(-10px);
          transition: transform 500ms ease-in, opacity 500ms ease;
          z-index: 1;
        }

        &:hover {
          .dropdown-menu {
            display: block;
            pointer-events: all;
            transform: translateY(0);
            opacity: 1;
          }

        }
      }
    }


    .nav-link {
      position: relative;
      overflow: hidden;
      img {
        transform: translate(-50%, 100%);
        transition: transform 125ms ease;
        width:15px;
        position: absolute;
        left: 50%;
        top: 100%;
      }
    }

    .nav-link {
      &:hover {
        img {
          transform: translate(-50%, -100%);
        }
      }
    }
  }
  &-link {
    text-transform: uppercase;
    font-weight: 700;
    font-size: .8rem;
  }
}


@include media-breakpoint-down(md) {
  .navbar-collapse {
    height: calc(100vh - 66px);
    opacity: 0;
    transition: opacity 500ms ease;
  }

  .navbar-nav {
    overflow-y: scroll;
  }

  .navbar-collapse,
  .navbar-collapse.collapse,
  .navbar-collapse.in,
  .navbar-collapse.collapsing {
    height: calc(100vh - 66px);
    transition: none;
  }
  .navbar-collapse.show {
    opacity: 1;
  }
}

.btn {
  text-transform: uppercase;
  &-block-xs { width: 100%; display:block; }
  &-block-sm { width: 100%; display:block; }
  &-block-md { width: 100%; display:block; }
  @include media-breakpoint-up(sm) {
    &-block-xs { width: auto; display:inline-block; }
  }
  @include media-breakpoint-up(md) {
    &-block-sm { width: auto; display:inline-block; }
  }
  @include media-breakpoint-up(lg) {
    &-block-md { width: auto; display:inline-block; }
  }
}

.h-wide {
  letter-spacing: 5px;
}

p:last-of-type {
  margin-bottom: 0;
}

.spacer-y-lg {
  padding-top: 4rem;
  padding-bottom: 4rem;
  @include media-breakpoint-up(md) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}

.spacer-t-lg {
  padding-bottom: 2rem;
  @include media-breakpoint-up(md) {
    padding-bottom: 6rem;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 10rem;
  }
}
.spacer-b-lg {
  padding-top: 2rem;
  @include media-breakpoint-up(md) {
    padding-top: 6rem;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 10rem;
  }
}

.result {
  &__item {
    width: 5rem;
    height: 5rem;
    @include media-breakpoint-up(md) {
      width: 7rem;
      height: 7rem;
    }
    @include media-breakpoint-up(lg) {
      width: 10rem;
      height: 10rem;
    }
  }

}

.footer {
  background: $primary url('../../assets/img/footer-bg.jpg') no-repeat center top;
  background-size: contain;
}

.hero-logo {
  text-align: center;
  img {
    max-width: 250px;
  }
}

.hero-bg {
  background-size: cover;
  background-position: center bottom;
}

.pattern-bg {
  @include media-breakpoint-up(lg) {
    display: block;
  }
  display: none;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: -50%;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 100% 100%;
}

.bg-solar-pattern {
  position: relative;
  background-position: center center;
  &::after{
    content:  '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(-45deg, rgba($primary, .5), rgba(darken($primary, 15%), .5));
  }

  &--content {
    position: relative;
    z-index: 1;
  }
}

.card-columns {
  column-count: 1;
  @include media-breakpoint-only(lg) {
    column-count: 2;
  }
  @include media-breakpoint-only(xl) {
    column-count: 3;
  }
}
@keyframes fade-in {
  from {opacity: 0; transform: translateY(2rem)}
  to {opacity: 1;}
}
.fade-in-element {
  animation: fade-in 1.4s;
}

.show-in-view {
  opacity: 0;
}

.project {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba($primary, .5), rgba(darken($primary, 25%), .5));
    opacity: 1;
    z-index: 1;
    transition: opacity 500ms ease-in-out;
  }

  > div {
    position: relative;
    z-index: 10;
  }
}

.carousel-slider {
  .carousel-inner {
    .carousel-item.active, .carousel-item-next, .carousel-item-prev {
      display: flex;
    }
    .carousel-item-right.active, .carousel-item-next {
      transform: translateX(25%);
    }
    .carousel-item-left.active, .carousel-item-prev {
      transform: translateX(-25%);
    }
    .carousel-item-right, .carousel-item-left {
      transform: translateX(0);
    }
  }
}

.uniform__potty {
  position: absolute;
  left: -9999px;
}


.marker {
  position: relative;
  border-radius: 50%;
  border: 8px solid $primary;
  width: 8px;
  height: 8px;
  box-sizing: content-box;
}

.marker::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  bottom: -30px;
  left: -6px;
  border: 10px solid transparent;
  border-top: 17px solid $primary;
}

#address {
  position: relative;
  &-results {
    position: absolute;
    top: 100%;
    max-height: 200px;
    overflow-y: scroll;
    z-index: 999;
  }
}